import './src/assets/styles/reset.css'
import './src/assets/styles/styling.css'
import './src/assets/styles/mobile.css'
import './src/assets/styles/fonts.css'

// Жесткий костыль, так как от near-js-api в случае отмены транзакции нас редиректит
// обратно с query_param, который содержит закодированный УРЛ с закодированным еще раз процентом (%).
// То есть пробел " " кодируется как "%2520" - роутер Gatsby/React'а не справлялся с этим
if (typeof window !== "undefined" && window?.location.href.includes('%25')) {
    window?.location.replace(decodeURI(window?.location.href))
}